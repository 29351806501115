import React from 'react';
import PropTypes from 'prop-types';
import { Card, Title } from '@lxlabs/change-ad-components';
import styled from 'styled-components';
import Graphic from './graphic';

const Style = styled(Card)`
  @media screen and (max-width: 990px) {
    padding: var(--spacing-medium) var(--spacing-large);
  }
`;

const GraphicIcon = styled(Graphic)`
  margin-bottom: var(--spacing-large);
`;

const TitleText = styled(Title)`
  margin-bottom: var(--spacing-large);
`;

const Content = styled.div`
  align-self: center;
  width: calc(100% - 50px);
`;

const Wrapper = styled.div`
  height: 100%;

  ${({ center }) => (center) && `
    @media not screen and (max-width: 834px) {
      display: flex;
      justify-content: center;
      grid-column: 1 / 3;
  
      & > div {
        width: calc(50% + (var(--spacing-small) * 3));
        // margin: 0 auto;
      }
    }
  `}
`;

const ContentCard = ({
  title, children, graphic, leftOffset, center,
}) => (
  <Wrapper center={center}>
    <Style padding={{}}>
      { graphic && <GraphicIcon size="medium" name={graphic} /> }
      <TitleText size="small">{title}</TitleText>
      <Content leftOffset={leftOffset}>
        {children}
      </Content>
      <span />
      {' '}
      {/* Span to stop the content sticking to the bottom */}
    </Style>
  </Wrapper>
);

ContentCard.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  graphic: PropTypes.string,
  leftOffset: PropTypes.bool,
  center: PropTypes.bool,
};

ContentCard.defaultProps = {
  graphic: '',
  leftOffset: false,
  center: false,
};

export default ContentCard;
